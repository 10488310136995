import { getItemInLocalStorage } from "../services/localStorage";

export const times = [
  "12:00 AM",
  "12:05 AM",
  "12:10 AM",
  "12:15 AM",
  "12:20 AM",
  "12:25 AM",
  "12:30 AM",
  "12:35 AM",
  "12:40 AM",
  "12:45 AM",
  "12:50 AM",
  "12:55 AM",
  "01:00 AM",
  "01:05 AM",
  "01:10 AM",
  "01:15 AM",
  "01:20 AM",
  "01:25 AM",
  "01:30 AM",
  "01:35 AM",
  "01:40 AM",
  "01:45 AM",
  "01:50 AM",
  "01:55 AM",
  "02:00 AM",
  "02:05 AM",
  "02:10 AM",
  "02:15 AM",
  "02:20 AM",
  "02:25 AM",
  "02:30 AM",
  "02:35 AM",
  "02:40 AM",
  "02:45 AM",
  "02:50 AM",
  "02:55 AM",
  "03:00 AM",
  "03:05 AM",
  "03:10 AM",
  "03:15 AM",
  "03:20 AM",
  "03:25 AM",
  "03:30 AM",
  "03:35 AM",
  "03:40 AM",
  "03:45 AM",
  "03:50 AM",
  "03:55 AM",
  "04:00 AM",
  "04:05 AM",
  "04:10 AM",
  "04:15 AM",
  "04:20 AM",
  "04:25 AM",
  "04:30 AM",
  "04:35 AM",
  "04:40 AM",
  "04:45 AM",
  "04:50 AM",
  "04:55 AM",
  "05:00 AM",
  "05:05 AM",
  "05:10 AM",
  "05:15 AM",
  "05:20 AM",
  "05:25 AM",
  "05:30 AM",
  "05:35 AM",
  "05:40 AM",
  "05:45 AM",
  "05:50 AM",
  "05:55 AM",
  "06:00 AM",
  "06:05 AM",
  "06:10 AM",
  "06:15 AM",
  "06:20 AM",
  "06:25 AM",
  "06:30 AM",
  "06:35 AM",
  "06:40 AM",
  "06:45 AM",
  "06:50 AM",
  "06:55 AM",
  "07:00 AM",
  "07:05 AM",
  "07:10 AM",
  "07:15 AM",
  "07:20 AM",
  "07:25 AM",
  "07:30 AM",
  "07:35 AM",
  "07:40 AM",
  "07:45 AM",
  "07:50 AM",
  "07:55 AM",
  "08:00 AM",
  "08:05 AM",
  "08:10 AM",
  "08:15 AM",
  "08:20 AM",
  "08:25 AM",
  "08:30 AM",
  "08:35 AM",
  "08:40 AM",
  "08:45 AM",
  "08:50 AM",
  "08:55 AM",
  "09:00 AM",
  "09:05 AM",
  "09:10 AM",
  "09:15 AM",
  "09:20 AM",
  "09:25 AM",
  "09:30 AM",
  "09:35 AM",
  "09:40 AM",
  "09:45 AM",
  "09:50 AM",
  "09:55 AM",
  "10:00 AM",
  "10:05 AM",
  "10:10 AM",
  "10:15 AM",
  "10:20 AM",
  "10:25 AM",
  "10:30 AM",
  "10:35 AM",
  "10:40 AM",
  "10:45 AM",
  "10:50 AM",
  "10:55 AM",
  "11:00 AM",
  "11:05 AM",
  "11:10 AM",
  "11:15 AM",
  "11:20 AM",
  "11:25 AM",
  "11:30 AM",
  "11:35 AM",
  "11:40 AM",
  "11:45 AM",
  "11:50 AM",
  "11:55 AM",
  "12:00 PM",
  "12:05 PM",
  "12:10 PM",
  "12:15 PM",
  "12:20 PM",
  "12:25 PM",
  "12:30 PM",
  "12:35 PM",
  "12:40 PM",
  "12:45 PM",
  "12:50 PM",
  "12:55 PM",
  "01:00 PM",
  "01:05 PM",
  "01:10 PM",
  "01:15 PM",
  "01:20 PM",
  "01:25 PM",
  "01:30 PM",
  "01:35 PM",
  "01:40 PM",
  "01:45 PM",
  "01:50 PM",
  "01:55 PM",
  "02:00 PM",
  "02:05 PM",
  "02:10 PM",
  "02:15 PM",
  "02:20 PM",
  "02:25 PM",
  "02:30 PM",
  "02:35 PM",
  "02:40 PM",
  "02:45 PM",
  "02:50 PM",
  "02:55 PM",
  "03:00 PM",
  "03:05 PM",
  "03:10 PM",
  "03:15 PM",
  "03:20 PM",
  "03:25 PM",
  "03:30 PM",
  "03:35 PM",
  "03:40 PM",
  "03:45 PM",
  "03:50 PM",
  "03:55 PM",
  "04:00 PM",
  "04:05 PM",
  "04:10 PM",
  "04:15 PM",
  "04:20 PM",
  "04:25 PM",
  "04:30 PM",
  "04:35 PM",
  "04:40 PM",
  "04:45 PM",
  "04:50 PM",
  "04:55 PM",
  "05:00 PM",
  "05:05 PM",
  "05:10 PM",
  "05:15 PM",
  "05:20 PM",
  "05:25 PM",
  "05:30 PM",
  "05:35 PM",
  "05:40 PM",
  "05:45 PM",
  "05:50 PM",
  "05:55 PM",
  "06:00 PM",
  "06:05 PM",
  "06:10 PM",
  "06:15 PM",
  "06:20 PM",
  "06:25 PM",
  "06:30 PM",
  "06:35 PM",
  "06:40 PM",
  "06:45 PM",
  "06:50 PM",
  "06:55 PM",
  "07:00 PM",
  "07:05 PM",
  "07:10 PM",
  "07:15 PM",
  "07:20 PM",
  "07:25 PM",
  "07:30 PM",
  "07:35 PM",
  "07:40 PM",
  "07:45 PM",
  "07:50 PM",
  "07:55 PM",
  "08:00 PM",
  "08:05 PM",
  "08:10 PM",
  "08:15 PM",
  "08:20 PM",
  "08:25 PM",
  "08:30 PM",
  "08:35 PM",
  "08:40 PM",
  "08:45 PM",
  "08:50 PM",
  "08:55 PM",
  "09:00 PM",
  "09:05 PM",
  "09:10 PM",
  "09:15 PM",
  "09:20 PM",
  "09:25 PM",
  "09:30 PM",
  "09:35 PM",
  "09:40 PM",
  "09:45 PM",
  "09:50 PM",
  "09:55 PM",
  "10:00 PM",
  "10:05 PM",
  "10:10 PM",
  "10:15 PM",
  "10:20 PM",
  "10:25 PM",
  "10:30 PM",
  "10:35 PM",
  "10:40 PM",
  "10:45 PM",
  "10:50 PM",
  "10:55 PM",
  "11:00 PM",
  "11:05 PM",
  "11:10 PM",
  "11:15 PM",
  "11:20 PM",
  "11:25 PM",
  "11:30 PM",
  "11:35 PM",
  "11:40 PM",
  "11:45 PM",
  "11:50 PM",
  "11:55 PM",
];

export const durations = [
  "15m",
  "30m",
  "45m",
  "01h",
  "01h 15m",
  "01h 30m",
  "01h 45m",
  "02h",
  "02h 15m",
  "02h 30m",
  "02h 45m",
  "03h",
  "03h 15m",
  "03h 30m",
  "03h 45m",
  "04h",
  "04h 15m",
  "04h 30m",
  "04h 45m",
  "05h",
  "05h 15m",
  "05h 30m",
  "05h 45m",
  "06h",
  "06h 15m",
  "06h 30m",
  "06h 45m",
  "07h",
  "07h 15m",
  "07h 30m",
  "07h 45m",
  "08h",
  "08h 15m",
  "08h 30m",
  "08h 45m",
  "09h",
  "09h 15m",
  "09h 30m",
  "09h 45m",
  "10h",
];

export const SOCKET_CONFIG = {
  URL: process.env.REACT_APP_SOCKET_URL,
  MAX_RETRY_ATTEMPTS: 3,
  RETRY_DELAY: 2000,
  AUTO_CONNECT: false,
  CONNECTED: "Connected",
  DISCONNECTED: "Disconnected",
};

// Socket Events
export const SOCKET_EVENTS = {
  // Connection events
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  CONNECT_ERROR: "connect_error",
  CONNECT_FAILED: "connect_failed",
  RECONNECT: "reconnect",
  RECONNECTING: "reconnecting",
  RECONNECT_ERROR: "reconnect_error",
  RECONNECT_FAILED: "reconnect_failed",
  ERROR: "error",

  // Custom events
  REGISTER_USER: "registerUser",
  WEBINAR_DATA: "webinarData",
};

export const RETRY_FAILED = "retryFailed"; // custom emit event

export const DEFAULT_POLLING_INTERVAL = "60000"; // 1 minute

export const POLLING_TIME_LIMIT = parseInt(
  process.env.REACT_APP_POLLING_INTERVAL || DEFAULT_POLLING_INTERVAL,
); // If ENV is not set, default to 1 minute

export const TOKEN_EXPIRED_MESSAGE =
  "Internal server error during authentication.";

export const WEBINAR_ENDED = "WEBINAR ENDED";

export const SCROLL_PAGINATION = {
  PAGE_SIZE: 25,
};

export const APP_DETAILS = {
  APP_TYPE: "infinipath",
  APP_VERSION: "1.0.0",
};

export const INVITE_FLAG = {
  ACCEPT: 'accept',
  VERIFY: 'verify',
}

export const MESSAGE = {
  MEMBER_ALREADY_VERIFIED: "Member is already verified",
  MEMBER_ALREADY_DELETED: "Member has already been deleted",
  MEMBER_DELETED:"Member request is already deleted"
}


// Retrieve and process configurations from localStorage
const rawConfigurations = getItemInLocalStorage("appConfigurations");
const parsedConfigurations = rawConfigurations ? JSON.parse(rawConfigurations) : [];

// Create constants dynamically based on the key-value pairs
export const CONFIGURATIONS = parsedConfigurations.reduce((acc: unknown, item: unknown) => {
  acc[item.key] = item.value;
  return acc;
}, {});
console.log(CONFIGURATIONS.joinMarkAttendance , "CONFIGURATIONS");
// Example: Create specific constants for easier access
export const FACIAL_RECOGNITION = CONFIGURATIONS.facialRecognition || false;
export const PREFERENCE = CONFIGURATIONS.preference || false;
export const ENABLE_JOIN_WITH_OTHERS = CONFIGURATIONS.joinWithOthers || false;
export const ADD_MEMBERS = CONFIGURATIONS.addMembers || false;
export const JOIN_MARK_ATTENDANCE = CONFIGURATIONS.joinMarkAttendance || false;
export const JOIN_OTP_VERIFICATION = CONFIGURATIONS.joinOtpVerification || false;




export const errorMessages = {
  firstNameRequired: "First name is required",
  firstNameInvalid: "First name must not contain numbers or special characters",
  firstNameMaxLength: "First name must not exceed 30 characters",
  lastNameRequired: "Last name is required",
  lastNameInvalid: "Last name must not contain numbers or special characters",
  lastNameMaxLength: "Last name must not exceed 30 characters",
  emailRequired: "Email is required",
  emailInvalid: "Invalid email format",
  cityRequired: "City is required",
  otherCityRequired: "Other City is required",
  phoneRequired: "Mobile number is required",
  dobRequired: "Date of birth is required",
  dobInvalid: "Date of birth is invalid",
  dobAgeRange: "Age must be between 3 and 100 years",
  genderRequired: "gender is required",
};

export const textConstants = {
  countryCode: "IN",
}