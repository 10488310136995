import { useState, useEffect } from "react";

const UseResize = () => {
  const [isMobileResolution, setIsMobileResolution] = useState(false);
  const [isTabResolution, setIsTabResolution] = useState(false);
  const [isHighResolution, setIsHighResolution] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobileResolution(screenWidth <= 600);
      setIsTabResolution(screenWidth > 600 && screenWidth <= 1024);
      setIsHighResolution(screenWidth > 1024);
    };

    if (typeof window !== "undefined") {
      handleResize(); // Call initially to set the correct state based on the current window size
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return { isMobileResolution, isTabResolution, isHighResolution };
};

export default UseResize;
