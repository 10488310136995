import { Outlet, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getItemInLocalStorage } from "../services/localStorage";
import Loader from "../common/components/Loader";


const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    // Simulating a small delay in checking authentication
    const token = getItemInLocalStorage("idToken");
    const loggedIn = getItemInLocalStorage("loggedIn") === "yes";

    setIsAuthenticated(token && loggedIn);
  }, []);

  // Show loading spinner while checking auth state
  if (isAuthenticated === null) {
    return <Loader type="large" />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
