import Axios from "axios";
import { getItemInLocalStorage, removeItemInLocalStorage } from "./localStorage";
import { getValidToken } from "./authService";
const BASE_URL = process.env.REACT_APP_API_URL;

const api = Axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getValidToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add response interceptor for global error handling
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorResponse = error.response;
    // Handle Bad Request (400) errors
    if (errorResponse && errorResponse.status === 400 && errorResponse.data?.message?.includes("Bad request, user not found")) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    }

    return Promise.reject(errorResponse);
  }
);

/**
 * @description This call is prior checking the user while login, before sending the OTP to check the user is already registered or not, this does not require any token
 */
export const postCallForGetUser = (url: string, payload: unknown) => {
  return Axios.post(BASE_URL + url, payload, {})
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const postCall = (url: string, payload: unknown) => {
  const userId = getItemInLocalStorage("localId");
  return api
    .post(BASE_URL + url, payload, {
      headers: {
        Authorization: `Bearer ${getItemInLocalStorage("idToken")}`,
        userid: typeof userId === "number" ? `'${userId}'` : userId,
      },
    })
    .then((response) => {
      if (response?.data?.message?.includes("User authorization has failed")|| (response.data.statusCode===403)) {
        signOut();
      }
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const putCall = (url: string, payload: unknown) => {
  const userId = getItemInLocalStorage("localId");
  return api
    .put(BASE_URL + url, payload, {
      headers: {
        Authorization: `Bearer ${getItemInLocalStorage("idToken")}`,
        userid: typeof userId === "number" ? `'${userId}'` : userId,
      },
    })
    .then((response) => {
      if (response?.data?.message?.includes("User authorization has failed")) {
        signOut();
      }
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCall = (url: string , signal?: AbortSignal) => {
  const userId = getItemInLocalStorage("localId");
  return api
  .get(BASE_URL + url, {
    headers: {
      Authorization: `Bearer ${getItemInLocalStorage("idToken")}`,
      userid: typeof userId === "number" ? `'${userId}'` : userId,
    },
    signal,
  })
    .then((response) => {
      if (response?.data?.message?.includes("User authorization has failed")) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
      }
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteCall = (url: string, payload: unknown) => {
  const userId = getItemInLocalStorage("localId");
  return api
    .delete(BASE_URL + url, {
      headers: {
        Authorization: `Bearer ${getItemInLocalStorage("idToken")}`,
        userid: typeof userId === "number" ? `'${userId}'` : userId,
      },
      data: payload, // Include the payload in the data property
    })
    .then((response) => {
      if (response?.data?.message?.includes("User authorization has failed")) {
        signOut();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error in deleteCall:", error);
      return error;
    });
};

export const getCallForFaceLogin = (url: string) => {
  return Axios.get(BASE_URL + url, {})
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};

/**
 * @description Function to sign out the user once the session expires
 */
const signOut = () => {
  // alert("Session expired. Please login again.");
  localStorage.clear();
  sessionStorage.clear();
  removeItemInLocalStorage("verification_status");
  window.location.href = "/";
};

/**
 * @description This call is to get user details this does not require any token
 */
export const getCallWithoutAuth = (url: string ,signal?: AbortSignal ) => {
  return Axios.get(BASE_URL + url , { signal })
    .then((response) => response)
    .catch((error) => {
        console.log(error, "error");
      return error.response;
    });
};

export const postCallWithoutAuth = (url: string, payload: unknown) => {
  return Axios.post(BASE_URL + url, payload, {})
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

// Function to make a PUT request to AWS URL without authentication
export const putCallWithoutAuthForAWSUrl = (url: string, file: File) => {
  return Axios.put(url, file, {
    headers: {
      "Content-Type": "image/jpeg", // Set the content type to image/jpeg
    },
  })
    .then((response) => response) // Handle successful response
    .catch((error) => {
      return error.response; // Handle error response
    });
};
