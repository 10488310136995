import React, { useState, useEffect, useRef } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../Firebase.js";
import styles from "./index.module.scss";
import { getItemInLocalStorage } from "../../services/localStorage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpFields from "../../components/OtpFields";
import { Button } from "../../common/components/Button";
import GrayLine from "../../common/components/GrayLine";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "../../common/components/Loader";
import { getCallWithoutAuth, postCall } from "../../services/apiService";
import {  textConstants } from "../../constants/index.ts";
import UseResize from "../../common/components/UseResize/index.tsx";
import {
  deleteSeekerAccount,
  modifyProfileUrl,
} from "../../utils/commonFunctions.ts";
import { useNavigate } from "react-router-dom";
import { endPoints } from "../../constants/urlConstants.ts";
import backArrow from "../../assets/images/back-arrow-icon.svg";

interface getUserPayload {
  phone_number: string;
  app_type: string;
}

interface Props {
  isOpen?: boolean;
  onclose?: () => void;
}

const VerifySeekerPhoneNumberNew: React.FC<Props> = ({ onclose }) => {
  const navigate = useNavigate();
  const { isMobileResolution } = UseResize();
  const [otp, setOtp] = useState("");
  const [isOTPEntered, setIsOTPEntered] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<unknown>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const loggedInUserData = getItemInLocalStorage("seekerDetails") || null;
  const [signInWithFace, setSignInWithFace] = useState(true);
  const [resetOtp, setResetOtp] = useState(false);
  const [disableButtons, setDisableButtons] = useState({
    verifyOTP: true,
  });
  const seekerDetails = getItemInLocalStorage("seekerDetails");

  console.log("signInWithFace", signInWithFace, loggedInUserData);

  const [phoneNumberWithoutCountryCode, setPhoneNumberWithoutCountryCode] =
    useState("");
  const mobileInputRef = useRef<HTMLInputElement | null>(null);

  console.log(phoneNumber, countryCode, "seekerPhoneNumber");

  /**
   * @description: This function is used to verify that the OTP is entered
   * @param otp
   * @param isComplete
   */
  const verifyOTPEntered = (otp: string, isComplete: boolean) => {
    if (otp?.length === 6) {
      setOtp(otp);
      setPhoneNumber(phoneNumber);
      setDisableButtons({ verifyOTP: !isComplete });
    } else {
      setOtp("");
      setDisableButtons({ verifyOTP: !isComplete });
    }
  };

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("Mobile number is required")
      .test(
        "is-matching-country-code",
        "Country code does not match",
        (value) => {
          if (!value) return false;
          // Remove any '+' prefix from the stored country code
          const storedCountryCode = seekerDetails?.countryCode?.replace(
            "+",
            "",
          );
          // Extract the country code part from the full number
          const inputCountryCode = value.substring(
            0,
            storedCountryCode?.length,
          );

          console.log("Comparing country codes:", {
            input: inputCountryCode,
            stored: storedCountryCode,
            fullValue: value,
          });

          return inputCountryCode === storedCountryCode;
        },
      )
      .test(
        "is-matching-phone-number",
        "Phone number does not match your registered number",
        (value) => {
          if (!value) return false;

          // Remove any '+' prefix from stored country code
          const storedCountryCode = seekerDetails?.countryCode?.replace(
            "+",
            "",
          );
          // Get the phone number part after country code
          const inputPhoneNumber = value.substring(storedCountryCode?.length);

          console.log("Comparing phone numbers:", {
            input: inputPhoneNumber,
            stored: seekerDetails?.phoneNumber,
            fullValue: value,
          });

          return inputPhoneNumber === seekerDetails?.phoneNumber;
        },
      )
      .test("is-valid-phone", "Mobile number is required", (value) => {
        if (value === countryCode) return false;
        else return true;
      }),
  });

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            console.log("recaptcha solved");
          },
        },
      );
    }
    return () => {
      window.recaptchaVerifier = null;
    };
  }, []);

  //to delete the account
  const handleDeleteAccount = async () => {
    try {
      setLoader(true);
      const response = await deleteSeekerAccount(seekerDetails?.id);
      if (response?.data?.statusCode === 200) {
        localStorage.clear();
        sessionStorage.clear();
        setLoader(false);
        navigate("/login");
      } else {
        setLoader(false);
        alert("Error deleting account");
      }
    } catch (error) {
      setLoader(false);
    }
  };

  /**
   * @description: This function is used to handle the seeker signup
   */

  const handleSeekerSignup = (fireBaseUid: string) => {
    const signUpPayload = {
      phoneNumber: phoneNumberWithoutCountryCode,
      appType: "infinipath",
      countryCode: countryCode?.startsWith("+")
        ? countryCode
        : `+${countryCode}`,
      firebaseIdExt: fireBaseUid,
    };

    postCall(endPoints.users, signUpPayload)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoader(false);
          const dataReq = response?.data?.data;
          // Replace the beginning of the profileUrl string for url masking
          if (dataReq?.profileUrl?.length > 0) {
            dataReq.profileUrl = modifyProfileUrl(dataReq?.profileUrl);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  /**
   * @param @description: This function is used to validate the user exist or not
   * @param data: form data
   */
  const validateUser = (payload: getUserPayload, fireBaseUid: string) => {
    // const formatPh = "+" + data?.phone;
    getCallWithoutAuth(
      `users/${payload?.phone_number}/validate?appType=infinipath`,
    )
      .then((res) => {
        console.log("validateUser", res);
        if (res?.data?.statusCode === 200) {
          handleDeleteAccount();
        } else if (res?.data?.statusCode === 400) {
          handleSeekerSignup(fireBaseUid);
        }
      })
      .catch((err) => {
        console.log("validateUser", err);
        setLoader(false);
        setPhoneNumberError("Failed to send the OTP, please try again later");
      });
  };

  /**
   * @description: This function is used to verify the OTP
   * @param pin
   */
  const verifyOTP = async (pin: string) => {
    const getUserPayload = {
      phone_number: phoneNumberWithoutCountryCode,
      app_type: "infinipath",
    };
    setLoader(true);
    try {
      const result = await confirmationResult.confirm(pin);
      if (result) {
        // Store IdToken and localId in local storage
        const idToken = result.user.accessToken;
        const localId = result.user.uid;
        console.log(idToken, "idToken");
        //calling validateUser API to check if user exists or not
        validateUser(getUserPayload, localId);
      }
    } catch (error) {
      setLoader(false);
      setOtpError("Invalid OTP, please try again.");
    }
  };

  const sendOtpHandler = (
    auth: unknown,
    phnNumber: string,
    recaptcha: unknown,
  ) => {
    setSignInWithFace(false);
    signInWithPhoneNumber(auth, phnNumber, recaptcha)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setSignIn(!signIn);
        setIsOTPEntered(true);
        setLoader(false);
        setSignIn(false);
        setResetOtp(true);
        setOtpError("");
      })
      .catch((error) => {
        setLoader(false);
        console.log(error.message, "error sending otp");
        if (error.message?.includes("too", "many")) {
          setPhoneNumberError("Too many requests, please try again later");
        } else {
          setPhoneNumberError("Failed to send the OTP, please try again later");
        }
      });
  };

  /**
   * @description: This function is used to verify the captcha and call send otp handler
   * @param phnNumber
   */
  const onCaptchVerify = async (phnNumber: string) => {
    try {
      const appVerifier = window.recaptchaVerifier;
      if (appVerifier) {
        sendOtpHandler(auth, phnNumber, appVerifier);
      } else {
        console.error("reCAPTCHA verifier not initialized");
        setLoader(false);
      }
    } catch (error) {
      console.error("Error in onCaptchVerify:", error);
      setLoader(false);
    }
  };

  /**
   * @description: This function is used to get the user data from
   * @param data
   */
  const onSubmit = async (data: formData) => {
    if (isOTPEntered && otp?.length === 6 && disableButtons.verifyOTP) {
      setLoader(true);
      verifyOTP(otp);
    } else if (!isOTPEntered) {
      setLoader(true);
      const phoneWithoutCountryCode = data?.phone?.slice(countryCode?.length);

      onCaptchVerify("+" + data?.phone);
      setPhoneNumberWithoutCountryCode(phoneWithoutCountryCode);
    }
  };

  const handleClearOtp = () => {
    setOtp("");
    setDisableButtons({ verifyOTP: true });
  };

  /**
   * @description: This function is used to resend the OTP
   * @param phone if it is signin flow, else it will be undefined and signupData.phone will be used
   */
  const resendOtp = (phone: string) => {
    handleClearOtp();
    setLoader(true);
    if (seekerDetails?.phoneNumber) {
      const formatPh = "+" + getValues("phone");
      onCaptchVerify(formatPh);
    } else {
      const formatPh = "+" + phone;
      onCaptchVerify(formatPh);
    }
  };

  /**
   * @description: This function is used to handle the form submit
   * @param data
   */
  const handleFormSubmit = (data: unknown) => {
    setPhoneNumberError("");
    onSubmit(data);
  };

  /**
   * @description: This function is used to handle enter key press to submit form
   * @param event
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      if (otp?.length !== 6) {
        event.preventDefault();
        handleSubmit(handleFormSubmit)();
      } else {
        verifyOTP(otp);
      }
    }
  };

  const [signIn, setSignIn] = useState(false);
  const [phone, setPhone] = useState<string>("91");

  useEffect(() => {
    setSignInWithFace(false);

    // Automatically focus the mobile input field when the component mounts
    if (mobileInputRef.current) {
      mobileInputRef.current.focus();
    }

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            console.log("recaptcha solved");
          },
        },
      );
    }
    return () => {
      window.recaptchaVerifier = null;
    };
  }, []);

  const handleBackArrow = () => {
    onclose && onclose();
  };
  const handleBackArrowOtp = () => {
    setIsOTPEntered(false);
    setPhoneNumberError("");
    setOtp("");
    setDisableButtons({ verifyOTP: true });
    setResetOtp(false);
  };

  return (
    <div className={styles.updateContainer}>
      <div className={styles.updateContainerdivv}>
        {!isOTPEntered ? (
          !isMobileResolution ? (
            <div className={styles.backArrowDiv}>
              <div className={styles.backArrow} onClick={handleBackArrow}>
                <img src={backArrow} alt="" className={styles.backArrowTab} />
              </div>
              <div className={styles.verifyHeading}>
                {" "}
                Verify your mobile number to delete your account
              </div>
            </div>
          ) : (
            <div className={styles.backArrowDiv}>
              <div className={styles.backArrow} onClick={handleBackArrow}>
                <img src={backArrow} alt="" className={styles.backArrowTab} />
              </div>
              <div className={styles.verifyHeading}>
                {" "}
                Verify your mobile number to delete your account
              </div>
            </div>
          )
        ) : !isMobileResolution ? (
          <div className={styles.backArrowDiv}>
            <div className={styles.backArrow} onClick={handleBackArrowOtp}>
              <img src={backArrow} alt="" className={styles.backArrowTab} />
            </div>
            <div className={styles.verifyHeading}>
              {" "}
              Verify your mobile number to delete your account
            </div>
          </div>
        ) : (
          <div className={styles.backArrowDiv}>
            <div className={styles.backArrow} onClick={handleBackArrowOtp}>
              <img src={backArrow} alt="" className={styles.backArrowTab} />
            </div>
            <div className={styles.verifyHeading}>
              {" "}
              Verify your mobile number to delete your account
            </div>
          </div>
        )}

        <div id="recaptcha-container" data-testid="recaptcha-container"></div>
        {/* {loader && <Loader type="large" data-testid="loader" />} */}
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          onKeyDown={handleKeyDown}
          className={
            !isOTPEntered
              ? `${styles.formTag}`
              : `${styles.formTag} ${styles.otpCardHeight}`
          }
          data-testid="login-form"
        >
          {!isOTPEntered ? (
            <div className={styles.phoneDiv}>
              <div
                className={styles.labelInput}
                data-testid="mobile-input-field"
              >
                <div className={styles.labelText}>Enter mobile number</div>
                <div className={styles.deletefieldBlock}>
                  <PhoneInput
                    country={textConstants?.countryCode}
                    value={phone}
                    onChange={(phone: string, country: unknown) => {
                      setCountryCode(country?.dialCode);
                      const cleanedPhone = phone ? phone.replace(/-/g, "") : "";
                      setPhone(cleanedPhone);
                      setValue("phone", cleanedPhone);
                      clearErrors("phone");
                    }}
                    containerClass={styles.loginCustomContainer}
                    inputClass={`${styles.loginCustomInput} ${errors.phone ? styles.errorFieldPhone : ""}`}
                    buttonClass={styles.loginCustomButton}
                    dropdownClass={styles.loginCustomDropdown}
                    enableSearch={true}
                    disableSearchIcon={true}
                    countryCodeEditable={false}
                    inputProps={{
                      ref: mobileInputRef,
                      autoFocus: true,
                      "data-testid": "phone-input-field",
                    }}
                    data-testid="phone-input"
                  />
                </div>
                {/* Showing invalid phone number error */}
                {errors.phone && (
                  <div
                    className={`${styles.error} ${styles.phoneErrorMobile}`}
                    data-testid="phone-number-error"
                  >
                    {errors.phone.message}
                  </div>
                )}
              </div>
              {!isOTPEntered && !loader && (
                <Button
                  buttonClassName={`${styles.submitButton} ${styles.getOtpBtnWidth}`}
                  type="submit"
                  datatestid="get-otp-button"
                  datatestidText="get-otp"
                >
                  get OTP
                </Button>
              )}
              {loader && (
                <div
                  className={
                    isOTPEntered
                      ? styles.getLoaderButtonDiv
                      : process.env.REACT_APP_ENABLE_FACE_ID === "false"
                        ? styles.getOTPButtonDiv
                        : `${styles.getOTPButtonDiv}`
                  }
                >
                  <Loader
                    type="Customized"
                    className={styles.loginHeight}
                    data-testid="loader"
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className={styles.formFields}>
                <div></div>
                <div className={styles.OTPForm} data-testid="otp-section">
                  <div className={styles.text} data-testid="otp-text">
                    Confirm OTP
                  </div>
                  <div className={styles.caption} data-testid="otp-enter">
                    <span data-testid="enter-otp-text">
                      Enter the OTP sent to +{getValues("phone")}
                    </span>

                    <p>to update your mobile number</p>
                  </div>
                  <div>
                    <OtpFields
                      onComplete={verifyOTPEntered}
                      setOtpError={setOtpError}
                      data-testid="otp-fields"
                      invalidOtp={resetOtp}
                      resetOtp={resetOtp}
                      setResetOtp={setResetOtp}
                    />
                    {otpError && (
                      <div
                        className={`${styles.error} ${styles.otpErrorPadding}`}
                        data-testid="otp-error"
                      >
                        {otpError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            // className={styles.buttonContainer}
            className={
              !isOTPEntered
                ? `${styles.buttonContainer && styles.notFaceButtons}`
                : ` ${styles.buttonContainer}`
            }
          >
            {isOTPEntered && !loader && (
              <div className={styles.otpResendDiv} data-testid="otp-resend-div">
                <Button
                  buttonClassName={`${styles.submitButton} ${styles.verifyBtnWidth}`}
                  type="button"
                  disable={disableButtons.verifyOTP}
                  onClick={() => {
                    // sendOTP();
                    verifyOTP(otp);
                  }}
                  datatestid="verify-otp-button"
                  datatestidText="verify"
                >
                  verify
                </Button>

                <>
                  {isMobileResolution && (
                    <div className={styles.orDiv} data-testid="or-divider">
                      <GrayLine />
                    </div>
                  )}
                  <span className={styles.caption2} data-testid="otp-caption">
                    did not receive an OTP?{" "}
                    <span
                      className={styles.resend}
                      onClick={() => {
                        resendOtp(getValues("phone"));
                      }}
                      data-testid="resend-otp-link"
                    >
                      resend OTP
                    </span>
                  </span>
                </>
              </div>
            )}

            {isOTPEntered && loader && (
              <div
                className={
                  isOTPEntered
                    ? styles.getLoaderButtonDiv
                    : `${styles.getOTPButtonDiv} ${styles.loaderButtonDiv}`
                }
              >
                <Loader
                  type="Customized"
                  className={styles.loginHeight}
                  data-testid="loader"
                />
              </div>
            )}
          </div>
          {isOTPEntered && phoneNumberError && (
            <div
              className={styles.errorResend}
              data-testid="phone-number-error"
            >
              {phoneNumberError}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default VerifySeekerPhoneNumberNew;
