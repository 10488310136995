export const setItemInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItemInLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  if (value !== null && value !== "undefined" && value !== undefined)
    return JSON.parse(value);
  return "";
};

export const removeItemInLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  if (value !== null && value !== undefined) localStorage.removeItem(key);
};


/** 
 * To store the face verified  seekers data while join with others . 
 * userId: the user id of the seeker
 * text: the text to be displayed  for attendance status 
 */
export const setArrayInLocalStorage = (key: string, newItem: { userId: string; text: string }) => {
  const existingData = JSON.parse(localStorage.getItem(key) || "[]");
  const updatedData = [...existingData, newItem];
  localStorage.setItem(key, JSON.stringify(updatedData));
};


/**
 * To clear the face verified seekers data in local storage in other screens
 */
export const clearArrayInLocalStorage = (key: string) => {
  localStorage.setItem(key, JSON.stringify([]));
};