import { createSlice } from "@reduxjs/toolkit";

interface verificationStatusState {
  meetingVerificationStatusArray: Array<unknown>;
}

const initialState: verificationStatusState = {
  meetingVerificationStatusArray: [],
};

const verificationStatusSlice = createSlice({
  name: "meetingVerificationStatus",
  initialState,
  reducers: {
    updateMeetingVerificationStatus: (state, data) => {
      state.meetingVerificationStatusArray.push(data.payload);
    },
    resetState: () => initialState, 
  },
});

export const { updateMeetingVerificationStatus , resetState } =
  verificationStatusSlice.actions;

export default verificationStatusSlice.reducer;
