import { combineReducers } from "redux";
import appReducer from "./AppReducer";
import zoomReducer from "./ZoomReducer";
import seekerReducer from "./SeekerReducer";
import verificationStatusReducer from "./VerificationStatusReducer";

const rootReducer = combineReducers({
  appReducer,
  zoomReducer,
  seekerReducer,
  verificationStatusReducer,
  // Add more reducers as needed
});

export default rootReducer;
